@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap");

:root {
  --black: #000000;
  --white: #ffffff;
  --light-gray: #f5f5f5;
  --dark-gray: #333333;
  --medium-gray: #aaaaaa;
  --transition-speed: 0.4s;
  --max-width: 1400px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Cormorant Garamond", serif;
  background-color: var(--black);
  color: var(--white);
  line-height: 1.4;
  letter-spacing: 0.2px;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  transition: opacity var(--transition-speed);

  &:hover {
    opacity: 0.7;
  }
}

button {
  cursor: pointer;
  font-family: "Cormorant Garamond", serif;
}

/* Container styles */
.page-container {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Background particles */
.background-particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Hero section */
.hero-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 40px;
  position: relative;
}

.hero-content {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hero-title {
  font-family: "Cinzel", serif;
  font-size: 8.5rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-bottom: 2rem;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.hero-subtitle {
  font-family: "Cormorant Garamond", serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 3rem;
  margin-top: 1rem;
  opacity: 0.9;
  font-style: italic;
  max-width: 800px;
}

.hero-buttons {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  position: relative;
  z-index: 2;
}

.elegant-button {
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 2px;
  background-color: transparent;
  color: var(--white);
  border: 1px solid var(--white);
  transition: all var(--transition-speed);
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: transform var(--transition-speed);
    z-index: -1;
  }

  &:hover {
    background-color: var(--white);
    color: var(--black);

    &::before {
      transform: translateX(100%);
    }
  }
}

/* Updated blog container styling for fixed positioning */
.elegant-blogs-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  margin-top: 1rem;
  padding-bottom: 5rem;
  &.active {
    max-height: 800px; // Adjust as needed
    opacity: 1;
  }
}

/* New elegant blogs list styling */
.elegant-blogs-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.elegant-blog-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all var(--transition-speed);
  opacity: 0;
  transform: translateY(20px);

  &.visible {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.03);
    opacity: 1;

    .elegant-blog-title {
      transform: translateX(10px);
    }

    .read-more-arrow {
      transform: translateX(5px);
    }
  }
}

.blog-content-wrapper {
  text-align: left;
  flex: 1;
}

.elegant-blog-title {
  font-family: "Cinzel", serif;
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
  color: var(--white);
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: transform var(--transition-speed);
}

.elegant-blog-excerpt {
  font-family: "EB Garamond", serif;
  font-size: 1.1rem;
  opacity: 0.8;
  line-height: 1.6;

  max-width: 90%;
}

.read-more-arrow {
  font-family: serif;
  font-size: 2rem;
  margin-left: 2rem;
  color: rgba(255, 255, 255, 0.6);
  transition: all var(--transition-speed);
}

/* Blogs section */
.blogs-section {
  padding: 6rem 40px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.blogs-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  max-width: var(--max-width);
  margin: 0 auto;
}

.blog-card {
  background-color: transparent;
  overflow: hidden;
  transition: all var(--transition-speed);
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity var(--transition-speed);
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .blog-image {
      transform: scale(1.05);
    }
  }
}

.blog-image-container {
  height: 300px;
  overflow: hidden;
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: all 0.8s ease;
}

.blog-content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.blog-title {
  font-family: "Cinzel", serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--white);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.blog-excerpt {
  font-size: 1.1rem;
  opacity: 0.8;
  line-height: 1.6;
}

/* Footer */
.footer {
  text-align: center;
  padding: 3rem 40px;
  font-size: 1rem;
  opacity: 0.7;
  letter-spacing: 1px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  a {
    font-style: italic;
  }
}

/* Media Queries */
@media (max-width: 992px) {
  .blogs-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .hero-title {
    font-size: 6.5rem;
  }

  .hero-subtitle {
    font-size: 1.8rem;
  }

  .elegant-blog-title {
    font-size: 1.6rem;
  }

  .elegant-blogs-container.active {
    max-height: 1000px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: auto;
    min-height: 100vh;
    padding: 3rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .hero-title {
    font-size: 4rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem auto 2rem;
    width: 100%;
    max-width: 500px;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
    align-items: center;
  }

  .elegant-button {
    width: 100%;
    text-align: center;
    padding: 0.9rem 1.5rem;
  }

  .elegant-blogs-container {
    margin-top: 2rem;
  }

  .elegant-blogs-container.active {
    max-height: 1200px;
    padding: 0 1.5rem;
  }

  .elegant-blogs-list {
    max-width: 100%;
  }

  .elegant-blog-item {
    padding: 1.8rem 1rem;
  }

  .elegant-blog-title {
    font-size: 1.4rem;
  }

  .elegant-blog-excerpt {
    font-size: 1rem;
    max-width: 100%;
  }

  .read-more-arrow {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .blogs-container {
    grid-template-columns: 1fr;
    padding: 0 1.5rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 1.5rem 1rem;
  }

  .hero-title {
    font-size: 3.2rem;
    letter-spacing: 0.03em;
  }

  .hero-subtitle {
    font-size: 1.3rem;
    margin-bottom: 1.8rem;
  }

  .elegant-button {
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
  }

  .elegant-blogs-container {
    margin: 2.5rem 0rem;
  }

  .elegant-blogs-container.active {
    max-height: 1800px;
    padding: 0 1rem;
  }

  .elegant-blog-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 1rem;
    margin: 0;
  }

  .blog-content-wrapper {
    width: 100%;
  }

  .elegant-blog-title {
    font-size: 1.3rem;
  }

  .read-more-arrow {
    margin-left: 0;
    margin-top: 1rem;
    align-self: flex-end;
  }

  .blogs-container {
    padding: 0 1rem;
  }
}

/* Blog page styles */
.elegant-blog-container {
  max-width: 800px;
  width: 100%;
  margin: 3rem auto;
  padding: 0 1.5rem;
}

.elegant-blog-header {
  text-align: center;
  margin-bottom: 3rem;
}

.elegant-blog-title-main {
  font-family: "Cinzel", serif;
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.elegant-blog-divider {
  width: 50px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 auto;
}

.elegant-blog-content {
  margin-top: 2rem;
}

.elegant-blog-section-title {
  font-family: "Cinzel", serif;
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin: 3.5rem 0 1.5rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    bottom: -10px;
    left: 0;
  }
}

.elegant-blog-subsection-title {
  font-family: "Cinzel", serif;
  font-size: 1.8rem;
  font-weight: 500;
  margin: 2.5rem 0 1.2rem;
  letter-spacing: 0.02em;
}

.elegant-blog-paragraph {
  font-family: "EB Garamond", serif;
  font-size: 1.25rem;
  line-height: 1.9;
  margin-bottom: 1.5rem;
  letter-spacing: 0.01em;

  a {
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.3s ease;
    padding-bottom: 1px;
    font-weight: 600;

    &:hover {
      border-color: var(--white);
    }
  }

  b {
    font-weight: 600;
  }
}

.elegant-blog-list {
  font-family: "EB Garamond", serif;
  font-size: 1.25rem;
  line-height: 1.9;
  margin-bottom: 2rem;
  padding-left: 1.5rem;
  letter-spacing: 0.01em;

  li {
    margin-bottom: 0.8rem;

    a {
      text-decoration: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      transition: all 0.3s ease;
      padding-bottom: 1px;
      font-weight: 600;

      &:hover {
        border-color: var(--white);
      }
    }

    b {
      font-weight: 600;
    }
  }
}

.elegant-blog-image-container {
  margin: 3rem 0;
  width: 100%;
}

.elegant-blog-image {
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  transition: filter 0.3s ease;

  &:hover {
    filter: grayscale(70%);
  }
}

.elegant-blog-navigation {
  margin: 4rem 0 2rem;
  display: flex;
  justify-content: center;
}

/* Media Queries for Blog */
@media (max-width: 992px) {
  .elegant-blog-title-main {
    font-size: 3rem;
  }

  .elegant-blog-section-title {
    font-size: 2.2rem;
  }

  .elegant-blog-subsection-title {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .elegant-blog-container {
    margin: 2rem auto;
    padding: 0 1.5rem;
  }

  .elegant-blog-title-main {
    font-size: 2.5rem;
  }

  .elegant-blog-section-title {
    font-size: 2rem;
  }

  .elegant-blog-subsection-title {
    font-size: 1.5rem;
  }

  .elegant-blog-paragraph,
  .elegant-blog-list {
    font-size: 1.15rem;
  }
}

@media (max-width: 480px) {
  .elegant-blog-title-main {
    font-size: 2rem;
  }

  .elegant-blog-section-title {
    font-size: 1.8rem;
  }

  .elegant-blog-subsection-title {
    font-size: 1.4rem;
  }

  .elegant-blog-paragraph,
  .elegant-blog-list {
    font-size: 1.05rem;
    line-height: 1.8;
  }

  .elegant-blog-container {
    padding: 0 1rem;
  }
}

/* Blog CTA Section */
.elegant-blog-cta {
  margin-top: 4rem;
  padding: 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 255, 255, 0.15);
  }

  h3 {
    font-family: "Cinzel", serif;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
    letter-spacing: 0.03em;
  }

  p {
    font-family: "EB Garamond", serif;
    font-size: 1.25rem;
    line-height: 1.8;
    margin-bottom: 1.8rem;
    letter-spacing: 0.01em;
  }

  .cta-button {
    display: inline-block;
    padding: 0.8rem 1.8rem;
    background-color: transparent;
    color: var(--white);
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-family: "Cinzel", serif;
    font-size: 1rem;
    text-decoration: none;
    letter-spacing: 0.1em;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.6);
    }
  }
}

@media (max-width: 768px) {
  .elegant-blog-cta {
    padding: 2rem 1.5rem;

    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1.15rem;
    }
  }
}

@media (max-width: 480px) {
  .elegant-blog-cta {
    padding: 1.5rem 1.2rem;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-size: 1.05rem;
      margin-bottom: 1.5rem;
    }

    .cta-button {
      width: 100%;
      text-align: center;
    }
  }
}
